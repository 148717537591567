  .image-gallery-view > ._2ILZE > ._2qwzr > ._3lwW_ > .__JnHV {
    background-color: #5E5C60 !important; 
    border-radius: 20px;
    width: fit-content;
    top: 0.4rem;
    position: relative;
    left: 1rem;
    padding-left:  0.6rem !important;
    
  }

  .close-icon {
    background-size: contain;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border-color: transparent;
    pointer-events: auto;
    cursor: default;
  }

  
  
  