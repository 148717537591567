.form-container{
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: black;
  color: white;
  border: 0vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 16px;
  padding: 12px 24px 12px 24px;
  flex-basis: content;
  z-index: 100;
}
.form-group{
  display: flex;
  flex-direction: row;
  padding-right: 24px;
  flex-basis: content;
}
.form-button{
  width: 91px;
  background-color: white;
  border: 2px solid white;
  color: black;
  border-radius: 8px;
  font-weight: bold;
}
.form-button:hover{
  width: 91px;
  background-color: gray;
  border: 2px solid gray;
  color: black;
  border-radius: 8px;

}
.form-button:active{
  width: 91px;
  background-color: gray;
  border: 2px solid gray;
  color: black;
  border-radius: 8px;

}
.form-button.error{
  width: 91px;
  background-color: white;
  border: 2px solid white;
  color: black;
  border-radius: 8px;
  max-height: 42px;
}



















 