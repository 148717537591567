/* @tailwind base;
@tailwind components;
@tailwind utilities; */
@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";

@font-face {
    font-family: "Montserrat";
    src: url("./fonts/static/Montserrat-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
.toggle-popup {
    position: relative;
    /* width:7rem; */
    z-index: 999;
    background-color: #fce89d;
    border-radius: 0.5rem;
    border: 1px solid #ccc;
    padding: 10px;
    transition: width 0.3s, height 0.3s; /* Smooth transition */
    overflow: hidden; /* Prevent overflow */
    height: fit-content !important; /* Auto height to fit content */
    width: fit-content !important;
  }
  .custom-popup {
    cursor: default;
    user-select: none;
    position: relative;
    z-index: 999;
    background-color: black;
    padding: 0px;
    left: 100px;
    border-radius: 25px;
  }
  .mapboxgl-popup-content {
    background-color: #fce89d !important;
    border-radius: 10px !important;  
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0) !important;  
    padding: 0px !important;  
    pointer-events: auto !important;  
    position: relative !important;  
    width: fit-content;
  }
  
  .mapboxgl-popup-tip {
    display: none;
    height: 0;
    width: 0;
    z-index: 1;
  }
  