.header {
  display: flex;
  font-family: "Montserrat";
  /* margin-left: 25%;
  margin-right: 25%; */

}
.date-input {
  margin-right: 1rem;
  width: 330px;
  cursor: default;
}

.mode-input {
  width: 165px;
  margin-right: 1rem;
}
.mode-input2 {
  width: 180px;
  margin-right: 1rem;
}

.header-container {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 2;
    top: 10px;
    display: none;
  }
 