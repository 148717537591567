/* .map-container {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  } */
  .map-wrapper {
    position: relative;
    width: 100%;
    height: 100vh; /* or your desired height */
  }
  
  .map-container {
    width: 100%;
    height: 100%; /* Full screen or your specified height */
  }
   
    .marker{
    background-color: black;
    background-size: cover;
    display: inline-block;
    color: white;
    font-weight: bold;
    height: 24px;
    width: 24px;
    display: flex; 
    align-items: center; 
    justify-content: center;
    border-radius: 50%;
    cursor: default;
    }