
  .sidebarStyle {
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
    margin: 12px;
    background-color: #404040;
    color: #ffffff;
    z-index: 1 !important;
    padding: 6px;
    font-weight: bold;
  }
  #toggleButton {
    position: fixed;
    z-index: 10;
    right: 60px;
    top: 90px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    cursor: default;
    border: none;
  }
  #toggleButton-user {
    position: fixed;
    z-index: 10;
    right: 60px;
    top: 100px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    cursor: default;
    border: none;
  }

  .row{
    display: flex;
    flex-direction: row;
  }
  .form-label{
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    font-size: 16px;
    margin-right: 12px;
    align-items: centre;
    justify-content: space-between;
    padding-top: 10px;
    color:#b1b1b1;
  }
  .form-label-error{
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    font-size: 16px;
    margin-right: 12px;
    align-items: centre;
    justify-content: center;
    padding-top: 6px;
    color:#b1b1b1;
  }
  .input-container{
    width: 100%;
  }
  .input-control {
    background-color:rgba(217,217,217,255);
    border-radius:8px;
    width: 207px;
    height: 38px;
    padding-left: 10px;
  }
  .form-control{
    box-shadow: none !important; 
    border-color: rgba(0,0,0,0.125) !important;
    border-radius: 8px !important; 
    outline: none;
    border: none;
  }
  
  .input-control:focus {
    background-color: white; 
  }
  
  .input-control.error {
    background-color: #FD8080; 
  }
  .text-danger {
    color: #FD8080 !important;
    font-style: italic;
    font-family: "Montserrat";
    font-size: 16px;
    text-align: start;
    margin-block-end: 0px;
    vertical-align: baseline;
    line-height: normal;
    padding-top: 10px;
  }
  .polygonIcon {
    z-index: 10;
    right: 60px;
    top: 95px;
    width: 30px;
    height: 30px;
    padding-right: 8px;
    padding-top: 3px;
    padding-left: 8px; 
    background-repeat: no-repeat;
    background-position: center center;
    position: fixed;
    background-color: black;
    border-radius: 20px;
    cursor: default;
  }
  
  .polygonIcon-selected {
    z-index: 10;
    right: 60px;
    top: 95px;
    width: 30px;
    height: 30px;
    padding-right: 6px; 
    padding-top: 1.5px;
    padding-left: 6px;
    background-repeat: no-repeat;
    background-position: center center;
    position: fixed;
    background-color: white;
    border-radius: 50%;
    border: 2px solid black;
    box-sizing: border-box;
    cursor: default;
  
    
  }
  #maplayer{
    position:fixed;
    z-Index:10;
    top:40px;
    left:60px;
    color: #ffffff;
    background-color: black;
    opacity: 0.6;
    padding: 10px;
  }
  #map-capture{
    position:fixed;
    z-Index:10;
    top:140px;
    left:60px;
    color: #ffffff;
    background-color: black;
    opacity: 0.6;
    padding: 10px;
  }
  #LogoutIcon {
  
    position: fixed;
    z-index: 10;
    right: 60px;
    top: 40px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    cursor: default;
    border: none;
  }
  #LogoutIcon-user {
    
    position: fixed;
    z-index: 10;
    right: 60px;
    top: 50px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    cursor: default;
    border: none;
  }
  .polygonIcon {
    z-index: 10;
    right: 60px;
    top: 140px;
    width: 30px;
    height: 30px;
    padding-right: 8px; 
    background-repeat: no-repeat;
    background-position: center center;
    position: fixed;
    background-color: black;
    padding-top: 3px;
    padding-left: 8px;
    border-radius: 20px;
    cursor: default;
    display: block;
  }
  
  .polygonIcon-selected {
    z-index: 10;
    right: 60px;
    top: 140px;
    width: 30px;
    height: 30px;
    padding-right: 7px; 
    padding-top: 3px;
    padding-left: 7px;
    background-repeat: no-repeat;
    background-position: center center;
    position: fixed;
    background-color: white;
    border-radius: 20px;
    border: 2px solid black;
    box-sizing: border-box;
    cursor: default;
    display: block;
  
    
  }
  .mapboxgl-marker {
    left: 0;
    opacity: 1 !important;
    position: absolute;
    top: 0;
    transition: opacity .2s;
    will-change: transform;
}
  .empty-marker {
    background-image: url("/public/icons/MarkerIcons/Property 1=empty-marker.svg");
    background-size: cover;
    display: inline-block;
    color: white;
    font-weight: bold;
    height: 36px;
    width: 36px;
    display: flex; 
    align-items: center; 
    justify-content: center;
    border-radius: 50%;
    cursor: default;
  }
  .empty-marker:hover {
    background-image: url("/public/icons/MarkerIcons/Property 1=empty-marker-selected.svg");
    background-size: cover;
    display: inline-block;
    color: black;
    font-weight: bold;
    height: 36px;
    width: 36px;
    display: flex; 
    align-items: center; 
    justify-content: center;
    border-radius: 50%;
    cursor: default;
  }
  /* .cluster-text{
    color:white;
  }
  .cluster-text:hover{
    color:rgb(0, 0, 0);
   
  } */
  
  
  .white-marker {
    background-image: url("/public/icons/MarkerIcons/Property 1=Waypoint.svg");
    /* background-size: cover; */
    width: 34px;
    height: 39px;
    /* border-radius: 50%; */
    cursor: default;
  }
  .white-marker:hover {
    background-image: url("/public/icons/MarkerIcons/Property 1=Waypoint-selected.svg");
    /* background-size: cover; */
    width: 34px;
    height: 39px;
    /* border-radius: 50%; */
    cursor: default;
  }
  .white-marker-selected{
    background-image: url("/public/icons/MarkerIcons/Property 1=Waypoint-selected.svg");
    /* background-size: cover; */
    width: 34px;
    height: 39px;
    /* border-radius: 50%; */
    cursor: default;
  }
  .purple-marker {
    background-image: url("/public/icons/MarkerIcons/Property 1=Snapshot.svg");
    /* background-size: cover; */
    width: 34px;
    height: 39px;
    /* border-radius: 50%; */
    cursor: default;
  }
  .purple-marker:hover {
    background-image: url("/public/icons/MarkerIcons/Property 1=Snapshot-selected.svg");
    /* background-size: cover; */
    width: 34px;
    height: 39px;
    /* border-radius: 50%; */
    cursor: default;
  }
  .purple-marker-selected {
    background-image: url("/public/icons/MarkerIcons/Property 1=Snapshot-selected.svg");
    /* background-size: cover; */
    width: 34px;
    height: 39px;
    /* border-radius: 50%; */
    cursor: default;
  }
  .yellow-marker {
    background-image: url("/public/icons/MarkerIcons/Property 1=Panorama.svg");
    /* background-size: cover; */
    width: 34px;
    height: 39px;
    /* border-radius: 50%; */
    cursor: default;
  }
  .yellow-marker:hover {
    background-image: url("/public/icons/MarkerIcons/Property 1=Panorama-selected.svg");
    /* background-size: cover; */
    width: 34px;
    height: 39px;
    /* border-radius: 50%; */
    cursor: default;
  }
  .yellow-marker-selected {
    background-image: url("/public/icons/MarkerIcons/Property 1=Panorama-selected.svg");
    /* background-size: cover; */
    width: 34px;
    height: 39px;
    /* border-radius: 50%; */
    cursor: default;
  }
  
  .cluster-marker {
  background: black;
  display: inline-block;
  color: white;
  font-weight: bold;
  height: 38px;
  width: 38px;
  display: flex; 
  align-items: center; 
  justify-content: center;
  border-radius: 50%;
  cursor: default;
  z-index: 10;
  
  }
  .progress-bar-custom {
    position:absolute;
    top: 1px;
    height: 4px;
    background-color: rgba(5, 114, 206, 0.2);
    width: 100%;
    overflow: hidden;
    z-index: 10;
    }
    .progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: rgb(92, 176, 245);
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
    z-index: 10;
    }
  @keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
  }
  .mapboxgl-ctrl-attrib.mapboxgl-compact, .mapboxgl-ctrl-attrib.mapboxgl-compact::after, .mapboxgl-ctrl-attrib.mapboxgl-compact::before {
  box-sizing:content-box;
  background-color: #4460ee;
  }
  .mapboxgl-canvas-container.mapboxgl-interactive, .mapboxgl-ctrl-group  button.mapboxgl-ctrl-compass {
  cursor: default;
  
  }
  a.mapboxgl-ctrl-logo{
  display: none;
  }
  .mapboxgl-ctrl-bottom-right {
  z-index: 10;
  }
  .mapboxgl-ctrl-attrib{
    display: none;
  }
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  float: right;
  margin: 0 0px 24px 0 !important;
  } 
  .mapboxgl-ctrl-group button:first-child {
  border-radius: 4cm 4cm 0 0 !important;
  cursor: default;
  }
  
  .mapboxgl-ctrl-group button:last-child {
  border-radius: 4cm !important;
  margin-bottom: 0px;
  }
  .mapboxgl-ctrl-group {
  background-color: transparent !important;
  box-shadow: none !important;
  cursor: default;
  }
  .mapboxgl-ctrl-group button+button {
  border-top: none;
  }
  .mapboxgl-ctrl-attrib-button:focus, .mapboxgl-ctrl-group button:focus {
  box-shadow: none;
  }
  /* .mapboxgl-ctrl-attrib-button {
  background-image: url("/public/icons/Control-Info.svg") !important;  
  background-repeat: no-repeat;
  background-position: center center;
  background-color: gray !important; 
  
  } */
  .mapboxgl-ctrl-attrib.mapboxgl-compact{
  
  right:50px !important;
  bottom: 46px !important; 
  border-radius: 50%;
  width: 0px ;
  height: 0px;
  background-color: gray;
  display: none;
  
  }
  .mapboxgl-ctrl-attrib.mapboxgl-compact-show{
  right:50px !important;
  bottom: 46px !important; 
  border-radius: 12px;
  width: 100% ;
  height: 100%;
  background-color: rgb(255, 255, 255);
  
  }
  .mapboxgl-ctrl-bottom-right {
  float: right;
  margin: 0;
  background: transparent;
  }
  
  .mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url("/public/icons/ControlIcons/Property 1=Zoom-bottom-button.svg") !important;
  background-size: 90% ;
  } 
  .mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon:hover {
  background-image: url("/public/icons/ControlIcons/Property 1=Zoom-bottom-button-hover.svg") !important;
  background-size: 100% ;
  cursor: default;
  
  } 
  .mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url("/public/icons/ControlIcons/Property 1=Default-Zoom-Top-button.svg") !important;
  background-size: 100% ;
  }
  .mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon:hover {
  background-image: url("/public/icons/ControlIcons/Property 1=Zoom-Top-button-hover.svg") !important;
  background-size: 100% ;
  }
  
  .mapboxgl-ctrl-compass .mapboxgl-ctrl-icon{
  background-image: url("/public/icons/ControlIcons/Property 1=Default-Compass.svg") !important; 
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgb(0, 0, 0);
  cursor: default;
  background-size: 100% ;
  border: none;
  }
  .mapboxgl-ctrl-compass .mapboxgl-ctrl-icon:hover{
  background-image: url("/public/icons/ControlIcons/Property 1=Compass-Hover.svg") !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-color:#343434 ;
  background: none;
  cursor: default;
  background-size: 100% ;
  border: none;
  
  }
  
  .mapboxgl-ctrl-bottom-right {
  bottom: 0;
  right: 60px !important;
  }
  
  .mapboxgl-ctrl-group .mapboxgl-ctrl-compass:hover{
  width: 30px ;
  height: 30px;
  border-radius: 50%;
  border-color:#5E5C60;
  background-color:#5E5C60 !important; 
  margin-top: 20px;
  }
  .mapboxgl-ctrl-group .mapboxgl-ctrl-compass{
  
  width: 30px ;
  height: 30px;
  border-radius: 50%;
  border-color: rgb(0, 0, 0);
  background-color: black !important; 
  margin-top: 20px;
  
  }
  .mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-out{
  
  width: 30px;
  height: 40px;
  border-radius: 0 0 4cm 4cm;
  border-color: rgb(0, 0, 0);
  background-color: black !important;
  
  }
  .mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-out:hover{
  width: 30px;
  height: 40px;
  border-radius: 0 0 4cm 4cm;
  border-color:#5E5C60;
  background-color:#5E5C60 !important;
  }
  .mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-in{
  background-color: black !important;
  width: 30px;
  height: 40px;
  /* border-radius: 50%;
  border-color: rgb(0, 0, 0); */
  }
  .mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-in:hover{
  background-color: rgba(128, 128, 128, 0.895);
  width: 30px;
  height: 40px;
  border-color:#5E5C60;
  background-color:#5E5C60 !important;
  }
  [aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.5;
  /* background-color: #4460ee !important; */
  }
  a img:last-child {
    display: none; 
     
  }
  a:hover img:last-child {
    display: inherit;  
    
  }
  a:hover img:first-child {
    display: none;  
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
   